export var getInitials = function (name) {
    // Check if the name is provided and not empty
    if (!name || name.trim().length === 0) {
        return ''; // or return a placeholder like 'N/A'
    }
    return name
        .split(' ')
        .map(function (part) { return part[0]; })
        .join('')
        .toUpperCase();
};
export function formatDate(dateString) {
    var date = new Date(dateString);
    var month = date.toLocaleString('en-US', { month: 'short' });
    var day = String(date.getDate()).padStart(2, '0');
    var year = date.getFullYear();
    return "".concat(month, ", ").concat(day, ", ").concat(year);
}
// Compare Date
export function isDateInFuture(givenDateString) {
    var givenDate = new Date(givenDateString);
    var today = new Date();
    // Reset the time portion to compare only the date part
    today.setHours(0, 0, 0, 0);
    givenDate.setHours(0, 0, 0, 0);
    return givenDate > today;
}
